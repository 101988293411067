.react-status-dropdown {
    @apply z-50;

    .status-badge {
        @apply items-center justify-center gap-2 h-[38px] px-3 py-2 rounded-md text-sm font-medium hidden sm:flex cursor-pointer;
    
        &.published {
            @apply bg-teal-500 text-white;
        }
        &.draft {
            @apply bg-amber-400 text-white;
        }
        &.paused {
            @apply bg-neutral-greige-300 text-slate-700;
            span {
                @apply text-slate-700;
            }
        }
        &.in_review {
            @apply bg-dark-blue-900 text-white;
        }
        &.loading, &.pending-changes {
            @apply bg-gray-400 cursor-not-allowed;
        }
        span {
            @apply text-white;
        }
    }

    .custom-select-options {
        @apply mt-[6px] absolute top-full right-0 px-2 py-2 rounded-lg shadow border border-stone-200 bg-white flex-col justify-start items-start inline-flex transition-opacity duration-200 max-h-40 overflow-auto z-50;
      }
      
      .custom-select-option {
        @apply self-stretch px-3 py-2 justify-between items-center gap-3 inline-flex rounded-lg z-50 cursor-pointer;
      
        &:focus,
        &:hover {
          @apply bg-gray-100;
        }
      
        span {
          @apply text-center text-neutral-800 text-[15px] font-semibold font-['Inter'] tracking-tight truncate;
        }
        .custom-select-option-label {
          @apply flex items-center gap-[14px];
        }
        .custom-select-option-check {
            @apply text-blue-500 shrink-0;
        }
      }
    
     .disabled {
        @apply bg-stone-50 border border-gray-300 text-gray-300 cursor-not-allowed pointer-events-none hover:bg-stone-300;
     }
}
.react-status-backdrop {
    @apply fixed top-0 left-0 w-full h-full cursor-pointer z-40;
}