body .ab-feed .ab-feed-buttons-wrapper {
  display: none;
}

body .ab-feed {
  background-color: white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body .ab-feed .ab-feed-body {
  border: none;
}

body .ab-feed.ab-effect-slide {
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
  transform: none;
}

body .ab-feed .ab-no-cards-message {
  display: none;
}

body .ab-feed .ab-card {
  border-radius: 10px;
}

body .ab-feed .ab-card .ab-unread-indicator {
  background-color: #CD9197;
}

body .ab-card-body {
  font-family: Poppins;
}

body .notification-dropdown-feed .ab-feed {
  width: 100%;
}

body .notification-dropdown-feed .ab-feed-body {
  width: 100%;
}