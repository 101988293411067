.image-text {
    &.value {
        @apply bg-transparent text-cyan-700 text-xs font-semibold leading-none tracking-tight;
    }
}

.preview-icon {
    @apply text-dark-blue-900 cursor-pointer transition-all duration-75 ease-in hover:scale-110 hover:text-cyan-700;
}

.preview-image-container {
    @apply flex justify-between items-center w-48 relative;
}

.image-dialog {
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @apply shadow-xl rounded-xl overflow-hidden max-w-screen-lg;
  }
  
  .overlay-image {

    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .image-close {
    @apply bg-transparent text-sm text-white absolute top-1 transition-all duration-75 ease-in right-1 scale-90 hover:scale-125 cursor-pointer;
  }

  .image-scroll-container {
    @apply max-h-[104px] overflow-hidden;
  }

