

.context-menu {
  background: white;
  border-radius: 4px;
  border-style: solid;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  position: absolute;
  z-index: 1000;
}

.context-menu-backdrop {
  position: fixed;
  top: 0;
  left: 5rem;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999;
  cursor: pointer;
}

.context-menu button {
  background: white;
  padding: 0.5em;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #0984e3;
  }
  ion-icon {
    font-size: 24px;
    color: #0984e3;
    margin-right: 16px;
  }
}

.context-menu button:hover {
  background: #c3e1f8;
}
