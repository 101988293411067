.edgeAddButton {
  @apply w-[38px] h-[34px] px-3 py-2.5 bg-[#344D80] rounded-lg shadow justify-center items-center gap-2 inline-flex z-2 relative cursor-pointer;
  pointer-events: all;
  &.readonly {
    @apply cursor-pointer bg-stone-400;
  }
}

.edgeRulesButton {
  @apply max-w-[30rem] px-3 py-2.5 bg-white rounded-lg shadow justify-center gap-2 inline-flex z-2 relative cursor-pointer;
  pointer-events: all;
  span {
    @apply text-left text-slate-800 text-sm font-semibold tracking-tight;
    @apply line-clamp-5 overflow-ellipsis;
  }
}

.rules-separator {
  @apply w-px h-[18px] bg-gray-400;
}