.status-badge {
    @apply items-center justify-center gap-2 h-[38px] px-3 py-2 rounded-md text-sm font-medium hidden sm:flex;

    &.published {
        @apply bg-teal-500 text-white;
    }
    &.draft {
        @apply bg-amber-400 text-white;
    }
    &.paused {
        @apply bg-neutral-greige-300 text-slate-700;
        span {
            @apply text-slate-700;
        }
    }
    &.in_review {
        @apply bg-dark-blue-900 text-white;
    }
    span {
        @apply text-white;
    }
}

.title-container {
    @apply p-6 w-screen bg-white justify-start items-center gap-6 inline-flex;
    h1 {
        @apply text-lg text-black sm:text-2xl font-medium tracking-tight truncate;
    }
}

.actions-container {
    width: calc(100vw - 5rem);
    @apply h-[50px] p-6 bg-neutral-100 justify-between items-center inline-flex gap-2;

    .algorithm-name {
        @apply justify-between items-center inline-flex shrink min-w-0 gap-2;
        .bk-button {
            @apply cursor-pointer text-gray-800 hover:text-gray-500 stroke-1 shrink-0;
        }
        .launch-button {
            @apply hidden cursor-pointer text-primary hover:text-primary-700 ml-4 stroke-1 shrink-0 sm:block;
        }
    }

    .actions {
        @apply justify-between items-center inline-flex gap-4;
    }

    h2 {
        @apply text-base text-black sm:text-xl font-medium tracking-tight truncate shrink min-w-8;
    }

    button {
        @apply w-[82px] h-[38px] px-3 py-2.5 bg-cool-blue rounded-lg justify-center items-center gap-2 inline-flex shrink-0;

        &:hover {
            @apply bg-cool-blue-600 cursor-pointer;
        }

        &.disabled {
            @apply bg-gray-300 cursor-not-allowed pointer-events-none hover:bg-gray-300;

            span {
                @apply text-gray-500;
            }
        }

        span {
            @apply text-center text-white text-[15px] font-semibold font-['Inter'] tracking-tight;
        }

        svg.ionicon {
            color: white;
            stroke: white !important;
        }
        &.formula-btn {
            @apply h-[38px] w-auto md:w-[155px] bg-transparent border border-primary;
            span {
                @apply text-primary grow hidden md:block;
            }
            svg {
                @apply shrink;
                stroke: #344D80 !important;
            }
            &:hover {
                @apply bg-primary;
                span {
                    @apply text-white;
                }
                svg {
                    stroke: white !important;
                }
            }
        }
    }
}

.title-panel {
    margin: 0px !important;
}