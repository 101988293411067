.content {
  pointer-events: none;
}

.handle {
  visibility: hidden;
}

.node-container {
  @apply rounded-xl overflow-hidden m-2 transition-all duration-100 ease-in w-80 cursor-pointer;
  pointer-events: all;
  .footer {
    @apply w-full px-4 py-2 items-center flex justify-center transition-all duration-[50ms] ease-in bg-cool-blue-600 hover:bg-cool-blue-700;
    button {
      @apply text-white text-sm font-medium leading-none tracking-tight border border-white rounded-md px-2 py-1 flex items-center justify-center;
    }
    &.readonly {
      @apply cursor-not-allowed;
      button {
        @apply cursor-not-allowed;
      }
    } 
  }
  &.question {
    // Colors
    --primary-node-question-color: #344D80;
    --primary-node-question-border-color: #0e7490;

    @apply border border-[--primary-node-question-border-color];
    .header {
      @apply w-full min-w-72 min-h-16 pl-6 pr-2 py-2 items-start flex justify-between bg-[--primary-node-question-color];
    }
    span.type, span.valuetype {
      @apply text-gray-500 text-sm font-medium leading-none tracking-tight mb-2;
    }
    span.title {
      @apply text-neutral-800 text-sm font-medium leading-tight tracking-tight mb-2 overflow-hidden overflow-ellipsis line-clamp-2;
    }
    span.desc {
      @apply text-neutral-800 text-xs leading-none tracking-tight mb-2 font-semibold overflow-hidden overflow-ellipsis line-clamp-5;
    }
    p.title {
      @apply w-full text-[#344D80] text-base font-medium leading-normal tracking-tight;
    }
    .separator {
      @apply my-2 border-b border-stone-200 w-full;
    }
  }
  &.information {
    // Colors
    --primary-node-information-color: #b45309;
    --primary-node-information-border-color: #b45309;
    
    @apply border border-[--primary-node-information-border-color];
    .header {
      @apply w-full min-w-72 min-h-16 pl-6 pr-2 py-2 items-start flex justify-between bg-[--primary-node-information-color];
    }
    span.type, span.valuetype {
      @apply text-gray-500 text-sm font-medium leading-none tracking-tight mb-2;
    }
    span.title {
      @apply text-neutral-800 text-sm font-medium leading-tight tracking-tight mb-2 overflow-hidden overflow-ellipsis line-clamp-2;
    }
    span.desc {
      @apply text-neutral-800 text-xs leading-none tracking-tight mb-2 font-semibold overflow-hidden overflow-ellipsis line-clamp-[10];
    }
    p.title {
      @apply w-full text-[#344D80] text-base font-medium leading-normal tracking-tight;
    }
    .separator {
      @apply my-2 border-b border-stone-200 w-full;
    }
  }
  &.outcome {
    // Colors
    --primary-node-outcome-color: #3f6212;
    --primary-node-outcome-border-color: #3f6212;
    @apply border border-[--primary-node-outcome-border-color];
    .header {
      @apply w-full min-w-72 min-h-16 pl-6 pr-2 py-2 items-start flex justify-between bg-[--primary-node-outcome-color];
    }
    span.type, span.valuetype {
      @apply text-gray-500 text-sm font-medium leading-none tracking-tight mb-2;
    }
    span.title {
      @apply text-neutral-800 text-sm font-medium leading-tight tracking-tight mb-2;
    }
    span.desc {
      @apply text-neutral-800 text-xs leading-none tracking-tight mb-2 font-semibold overflow-hidden overflow-ellipsis line-clamp-5;
    }
    p.title {
      @apply w-full text-[#344D80] text-base font-medium leading-normal tracking-tight;
    }
    .advice-text {
      @apply text-neutral-800 text-xs font-medium font-['Inter'] leading-none tracking-tight line-clamp-3;
    }
    .card-container {
      @apply w-full flex flex-col gap-2 p-2 rounded-lg border border-stone-200;
    }
    .primary-heading {
      @apply w-[72.50px] text-neutral-800 text-xs font-medium font-['Inter'] leading-none tracking-tight break-words;
    }
    .primary-content {
      @apply text-neutral-800 text-xs font-semibold font-['Inter'] leading-none tracking-tight ml-2 line-clamp-3;
    }
    .secondary-heading {
      @apply w-[72.50px] text-xs text-gray-500 font-medium leading-none tracking-tight;
    }
    .secondary-content {
      @apply text-gray-500 text-xs leading-none tracking-tight font-bold ml-2;
    }
    .sub-title-cols {
      @apply grid auto-cols-max grid-cols-2 gap-y-2;
    }
    .separator {
      @apply my-2 border-b border-stone-200 w-full text-red-700;
    }
  }
  &.requires-translation {
    // override colors
    --primary-node-question-color: #b91c1c;
    --primary-node-question-border-color: #b91c1c;
    --primary-node-information-color: #b91c1c;
    --primary-node-information-border-color: #b91c1c;
    --primary-node-outcome-color: #b91c1c;
    --primary-node-outcome-border-color: #b91c1c;
  }
  &.selected {
    @apply border-2;
  }
}

.placeholder-container {
  @apply m-1 transition-all duration-100 ease-in w-80 justify-center items-center gap-2 inline-flex;
  .placeholder {
    @apply w-[76px] h-[38px] px-3 py-2.5 rounded-lg border border-solid border-blue-900 justify-center items-center gap-2 inline-flex;
    span {
      @apply text-center text-black text-[15px] font-semibold font-['Inter'] tracking-tight;
    }
    &.selected {
      @apply bg-blue-900;
      span {
        @apply text-white;
      }
    }
  }
}