.node-actions-container {
    @apply px-3 py-2.5 rounded-lg border border-stone-200 justify-center items-center gap-2 inline-flex
}

.node-actions-separator {
    @apply w-0.5 h-4 bg-stone-200;
}

.node-actions-icon {
    @apply stroke-stone-200 transition-all duration-150 ease-in cursor-pointer;
    &:hover {
        @apply stroke-white;
    }
}

.node-actions-container.readonly {
    @apply cursor-not-allowed;
}