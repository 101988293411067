.chatActionButtonWrapper {
  @apply h-[38px] justify-start items-start inline-flex;
}

.chatActionButton {
  @apply px-3 py-2.5 rounded-lg border border-[#344d80] dark:border-white text-[#344d80] dark:text-white text-[15px] justify-center items-center gap-2 flex;
}

.callInProgressNotification {
  @apply opacity-5 bg-green-600 animate-pulse -left-1 -top-0.5;
}

.pinnedMessageNotification {
  @apply w-full flex justify-between bg-[#f2cf1f33] cursor-pointer border-b-slate-400 border-b-2;
}

.chatVerticalDivider {
  @apply w-px self-stretch bg-[#e5e3de] dark:bg-black;
}

.str-chat__edit-message-form-options {
  @apply gap-2
}
