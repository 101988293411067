.react-language-dropdown {
    @apply z-50;
    .language-dropdown-input {
        @apply max-w-64 h-[38px] w-auto px-3 py-2.5 shrink-0 bg-blue-900 rounded-lg justify-center items-center gap-2 inline-flex hover:bg-blue-800 disabled:bg-gray-500 disabled:cursor-not-allowed
    }
    .language-dropdown-input-text {
        @apply text-center text-white text-[15px] font-normal font-['Inter'] tracking-tight;
    }
    .language-dropdown-input-chevron {
        @apply text-white shrink-0;
    }
    .custom-select-options {
        @apply mt-[6px] absolute top-full right-0 min-w-60 w-full px-2 py-2 rounded-lg shadow border border-stone-200 z-40 bg-white flex-col justify-start items-start inline-flex transition-opacity duration-200 max-h-40 overflow-auto z-50;
      }
      
      .custom-select-option {
        @apply self-stretch px-3 py-2 justify-between items-center gap-3 inline-flex rounded-lg z-50 cursor-pointer;
      
        &:focus,
        &:hover {
          @apply bg-gray-100;
        }
      
        span {
          @apply text-center text-neutral-800 text-[15px] font-semibold font-['Inter'] tracking-tight truncate;
        }
        .custom-select-option-label {
          @apply flex items-center gap-[14px];
        }
        .custom-select-option-check {
            @apply text-blue-500 shrink-0;
        }
      }
    
     .disabled {
        @apply bg-stone-50 border border-gray-300 text-gray-300 cursor-not-allowed pointer-events-none hover:bg-stone-300;
     }
}
.react-language-backdrop {
    @apply fixed top-0 left-0 w-full h-full cursor-pointer z-40;
}