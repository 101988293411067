/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;

// Stream chat
@import 'stream-chat-angular/src/assets/styles/scss/index.scss';
@import "stream-chat-react/dist/scss/v2/index.scss";


// React Flow
@import "../node_modules/reactflow/dist/style.css";
@import "portal/algorithm-builder/ui/react/node-types/node.module.scss";
@import "./portal/algorithm-builder/ui/react/edge-types/edge.module.scss";
@import "./portal/algorithm-builder/ui/react/context-menu/context-menu.scss";
@import "./portal/algorithm-builder/ui/react/title-panel/title-panel.scss";
@import "./portal/algorithm-builder/ui/react/node-types/node-actions.scss";
@import "./portal/algorithm-builder/ui/react/shared/option-list/option-list.scss";
@import "./portal/algorithm-builder/ui/react/shared/image-option-list/image-option-list.scss";
@import "./portal/algorithm-builder/ui/react/shared/language-dropdown/language-dropdown.scss";
@import "./portal/algorithm-builder/ui/react/shared/status-dropdown/status-dropdown.scss";
@import "./shared/components/stream-video-call/react/stream.scss";
@import "./shared/components/getstream-chat/react/custom-chat-components.scss";

// Toasts
@import 'ngx-toastr/toastr';

//Font
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
// Quill
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import "~@tsed/tailwind-formio/styles/index.css";
@import "node_modules/font-awesome/scss/font-awesome.scss";
//Flags
@import "/node_modules/flag-icons/css/flag-icons.min.css";

// Braze customizations
@import "./app/services/braze.service/braze.styles.scss";


// IMPORTANT THIS IS BEFORE UTILITIES
@import "./styles-custom.scss";
@tailwind utilities;