.badge {
    @apply px-3 py-2.5 max-w-36 bg-gray-100 rounded-lg text-center text-slate-800 text-xs font-medium font-['Inter'] leading-tight;
    span {
        @apply overflow-hidden overflow-ellipsis line-clamp-1;
        box-sizing: content-box;
        white-space: normal;
    }
}
.option-value {
    @apply  px-3 py-2.5 min-h-[35.3px] bg-transparent text-cyan-700 font-semibold text-xs leading-none tracking-tight;
    span {
        @apply overflow-hidden overflow-ellipsis line-clamp-1;
        box-sizing: content-box;
        white-space: normal;
    }
}
span.options {
    @apply w-20 text-neutral-800 text-xs leading-none tracking-tight mb-2 font-semibold overflow-hidden overflow-ellipsis line-clamp-5;
}

span.options-image {
    @apply w-20 text-neutral-800 text-xs leading-none tracking-tight mb-2 font-semibold overflow-hidden overflow-ellipsis line-clamp-5;
}

span.optionValue {
    @apply w-28 h-16 min-h-16 text-left text-cyan-700 text-xs font-semibold font-['Inter'] leading-none tracking-tight truncate;
}

.optionLabel {
    @apply text-stone-200 text-xs font-semibold leading-none tracking-tight text-left;
}

.options-scroll-container {
    @apply flex max-h-36 overflow-hidden;
}

.more-options {
    @apply  pl-20 text-xs text-gray-300 font-semibold leading-none tracking-tight text-left;
}